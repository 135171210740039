import ajax from 'axios'
const axios = ajax.create()
axios.defaults.baseURL='https://contract.youmijob.com'//如果vue.config开启了跨域处理则改成 /fdd 否则 https://contract.youmijob.com
axios.interceptors.response.use(res =>{
    return res.data
},err=>{
    return Promise.reject(err)
})
const request = function ({url,method,params,data,responseType}) {
    return axios({
        url,
        method,
        params,
        data,
        responseType,
        timeout:30000
    })
}

export default request
