import ajax from 'axios'
import store from '../store/index'
const axios = ajax.create()
import {Message,Loading} from "element-ui";
axios.defaults.baseURL='/test'
axios.interceptors.response.use(res =>{
    if(res&&res.status==200){
        if(res&&res.headers&&res.headers.token){
            sessionStorage.setItem('YM_TOKEN',JSON.stringify(res.headers.token))
        }
        if(res&&res.data&&res.data.code==1000){
            return res.data
        }else{
            //根据需求可加特殊处理
            return res.data
        }
    }else{
        Message.error('服务器异常')
    }
},err=>{
    Loading.service({}).close()//全局关闭加载遮罩
    if(err&&err.response){
        if(err&&err.response&&err.response.headers&&err.response.headers.token){
            sessionStorage.setItem('YM_TOKEN',JSON.stringify(err.response.headers.token))
        }
        if(err.response.data&&(err.response.data.status==888||err.response.data.status==999)){
            Message.error('身份过期，请重新登录')
            store.commit('quitLogin')//退出登录
            return err.response
        }
    }
    Message.error('服务器异常')
    return err&&err.response?err.response:err
})
const request = function ({url,method,params,data,headers,responseType}) {
    let TOKEN
    if(localStorage.getItem('YM_INFO')){
        if(sessionStorage.getItem('YM_TOKEN')||JSON.parse(localStorage.getItem('YM_INFO')).token){
            TOKEN = sessionStorage.getItem('YM_TOKEN')||JSON.parse(localStorage.getItem('YM_INFO')).token
            TOKEN=TOKEN.substring(1,TOKEN.length-1)//去除双引号
        }
    }
    if(TOKEN){
        axios.defaults.headers.common['token']=TOKEN;
    }else{
        delete axios.defaults.headers.common['token'];
    }
    return axios({
        url,
        method,
        params,
        data,
        responseType,
        headers,
        timeout:30000
    })
}

export default request
