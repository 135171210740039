import {getSignetList,getProjectList} from '@/api/contract'
import {getClockInMngList} from "@/api/clockIn";
import {getFunctionThree} from '@/api/authority'
//获取印章管理清单
export function getSignetListShare() {
    return getSignetList()
}
//获取项目清单
export function getProjectListShare(projectname,state) {
    return getProjectList(projectname,state)
}
//获取考勤组列表
export function getClockInTempListShare() {
    return  getClockInMngList({data:{}})
}
//获取所有功能树
export function getFunctionThreeShare() {
    return  getFunctionThree()
}
