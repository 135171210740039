import request from "../util/require";
import upload from '../util/upload';//本地需要测试上传接口时切换
import fddApi from '../util/fadada'
/**
 * 手机号登录接口
 * @param obj
 * @returns {Promise<AxiosResponse<any>>}
 */
export function login(params) {
    return request({
        url: '/paasbase/user/smsLogin', method: 'get', params
    })
}

/**
 * 手机号获取验证码接口
 * @param obj
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCode(params) {
    return request({
        url: '/paasbase/user/getVerficationCode', method: 'get', params
    })
}

/**
 * 设置支付密码
 * @param data
 * @returns {*}
 */
export function setPaymentPW(data) {
    return request({
        url: '/pay/password/save', method: 'post', data
    })
}

/**
 * 检测是否设置过支付密码
 * @param data
 * @returns {*}
 */
export function checkPaymentPW(data) {
    return request({
        url: '/pay/password/check', method: 'post', data
    })
}

/**
 * 获取普通管理员公司名称等信息
 * @param data
 * @returns {*}
 */
export function getUserCompanyAndName(data) {
    return request({
        url: '/paasbase/user/getSuperInfo', method: 'post', data
    })
}
/**
 * 获取所有字典
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getDict() {//获取所有字典信息接口
    return request({
        url: '/paasbase/system/dictionary/getDict',
        method: 'GET',
    })
}

/**
 * 上传图片接口
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadApi(file) {//
    return request({
        url: '/upload/img',
        method: 'post',
        data: file,
        headers: {
            //文件上传用该formdata类型请求头
            'Content-Type': 'multipart/form-data'
        }
    })
}

/**
 * 上传文档接口
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadXlsApi(file) {//
    return request({
        url: '/upload/xls',
        method: 'post',
        data: file,
        headers: {
            //文件上传用该formdata类型请求头
            'Content-Type': 'multipart/form-data',
        }
    })
}

/**
 * 法大大上传接口
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fddUpload(file) {//
    return fddApi({
        url: '/file/upload',
        method: 'post',
        data: file,
        headers: {
            //文件上传用该formdata类型请求头
            'Content-Type': 'multipart/form-data'
        }
    })
}

/**
 * 上传证书接口
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadCRTApi(file) {//
    return request({
        url: '/upload/certificate',
        method: 'post',
        data: file,
        headers: {
            //文件上传用该formdata类型请求头
            'Content-Type': 'multipart/form-data'
        }
    })
}


/**
 * 获取员工详情
 * @param data
 * @returns {*}
 */
export function getPersonDetail(data) {
    return request({
        url: '/paasuser/staff/getStaffByPersonId', method: 'post', data
    })
}

/**
 * 获取员工发薪记录
 * @param data
 * @returns {*}
 */
export function getPersonPayrollList(data) {
    return request({
        url: '/app/payroll/getPayrollRecord', method: 'post', data
    })
}

/**
 * 获取员工发薪记录
 * @param data
 * @returns {*}
 */
export function getPersonProject(data) {
    return request({
        url: '/paasuser/project/getProjectListByPerson', method: 'post', data
    })
}

/**
 * 成为服务商
 * @param data
 * @returns {*}
 */
export function addBecomeService(data) {
    return request({
        url: '/eu/company/service/apply/add', method: 'post', data
    })
}
/**
 * 视频上传 获取url
 * @param data
 * @returns {*}
 */
export function getVideoUploadUrl(data) {
    return request({
        url: '/paasbase/insurance/mini/upload', method: 'post', data
    })
}
// 获取发薪放款支付方式
export function getPayWay(data) {
    return request({
        url: '/bill/payment/get', method: 'post', data
    })
}