import request from "../util/require";

/**
 * 获取发布 职位发布 获取公司列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCompanyList(data) {
    return   request({
        url:'/projectManagement/getCompanyList',method:'get',params:data
    })
}

/**
 *区域管理 合作企业 -- 所有公司列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getALLCompanyList(data) {
    return   request({
        url:'/areaManage/getCompanyList',method:'post',data
    })
}
/**
 * 区域管理 合作企业 -- 删除
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delCompanyLi(companyId) {
    return   request({
        url:'/areaManage/deleteCompany',method:'get',params:companyId
    })
}

/**
 * 区域管理 合作企业 -- 修改状态
 * @param companyId
 * @param status
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterCompanyLi(companyId,status) {
    return   request({
        url:'/areaManage/modifyCompanyStatus',method:'get',params: {companyId,status}
    })
}
/**
 * 获取发布 职位类别
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPositionClass(type) {
    return   request({
        url:'/industry/getByTree',method:'get',params: {type}
    })
}

/**
 * 全职 发布职位
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addFullPublish(data) {
    return   request({
        url:'/jobManagement/postJob',method:'post',data
    })
}

/**
 * 全职 修改职位
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterFullPublish(data) {
    return   request({
        url:'/jobManagement/modifyJobInformation',method:'post',data
    })
}
/**
 * 获取平台职位 表格列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPositionListTable(data) {
    return   request({
        url:'/jobManagement/getJobList',method:'post',data
    })
}

/**
 * 修改平台职位状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterPositionState(data) {
    return   request({
        url:'/jobManagement/updateState',method:'post',data
    })
}

/**
 * 获取区域职位 表格列表数据
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getRegionPositionTable(data) {
    return   request({
        url:'/jobManagement/getAreaJobList',method:'post',data
    })
}
/**
 * 修改区域职位状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterRegionPositionState(data) {
    return   request({
        url:'/jobManagement/updateAreaJobState',method:'post',data
    })
}

/**
 * 删除平台职位状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delRegionPosition(No) {
    return   request({
        url:'/jobManagement/deleteJob',method:'get',params: {No}
    })
}
/**
 * 招聘管理 获取人才招聘 -- 待处理表格数据
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getTalentPending(data) {
    return   request({
        url:'/recruitmentManagement/getUserCompanyList',method:'post',data
    })
}

/**
 * 招聘管理 获取面试时间轮次
 * @param companyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getTimeRound(companyId) {
    return   request({
        url:'/postRelease/getRoundList',method:'get',params:{companyId:Number(companyId)}
    })
}
/**
 * 招聘管理 人才招聘 -- 待处理》可面试 接口
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addTalentCanView(data) {
    return   request({
        url:'/recruitmentManagement/addUserInterview',method:'post',data
    })
}
/**
 * 招聘管理 人才招聘 -- 人才招聘》约面试 接口
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addTalentAppointView(data) {
    return   request({
        url:'/recruitmentManagement/updateUserInterview',method:'post',data
    })
}
/**
 * 招聘管理 提报库 -- 提报库》约面试 接口
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addSubmitAppointView(data) {
    return   request({
        url:'/recruitmentManagement/updateInterview',method:'post',data
    })
}
/**
 * 招聘管理 人才招聘 -- 可面试、待入职、未入职、已离职
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getTalentAnyTable(data) {
    return   request({
        url:'/recruitmentManagement/getResumeList',method:'post',data
    })
}
/**
 * 招聘管理 人才招聘 -- 通用删除接口
 * @param id number
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delTalentAnyRow(id,postadcode,year) {
    return   request({
        url:'/recruitmentManagement/delUserInterview',method:'get',params:{id,postadcode,year}
    })
}

/**
 * 灵工管理 考勤管理--新增考勤组
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addCheckAttTeam(data) {
    return   request({
        url:'/spiritWorkManagement/addAttenceTeam',method:'post',data
    })
}
/**
 * 灵工管理 考勤管理--获取考勤组列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCheckAttTeamList(data) {
    return   request({
        url:'/spiritWorkManagement/getAttenceTeamList',method:'post',data
    })
}

/**
 * 灵工管理 考勤管理--修改考勤组
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterCheckAtt(data) {
    return   request({
        url:'/spiritWorkManagement/modifyAttenceTeam',method:'post',data
    })
}

/**
 * 灵工管理 考勤管理--根据id获取当前考勤组剩余信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getTeamOtherInfo(teamId) {
    return   request({
        url:'/attendance/getAttenArea',method:'get',params:{teamId}
    })
}
/**
 * 灵工管理 考勤记录--获取考勤记录列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCheckAttTable(data) {
    return   request({
        url:'/spiritWorkManagement/getAttenceHistoryList',method:'post',data
    })
}

/**
 * 财务管理 预支管理
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAdvanceList(data) {
    return   request({
        url:'/financialManagement/allWithdrawal',method:'post',data
    })
}

/**
 * 财务管理 预支管理 放款失败 列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getLoanFailureList(data) {
    return   request({
        url:'/financialManagement/allFailWithdrawal',method:'post',data
    })
}
/**
 * 财务管理 预支管理 修改审核状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterAdvanceState(data) {
    return   request({
        url:'/financialManagement/processState',method:'post',data
    })
}
/**
 * 财务管理 预支管理 驳回预支
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function rejectAdvance(data) {
    return   request({
        url:'/financialManagement/processNotState',method:'post',data
    })
}
/**
 * 财务管理 预支管理 获取验证码
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAdvanceCode(params) {
    return   request({
        url:'/adminLogin/getVerficationCode',method:'get',params
    })
}

/**
 * 财务管理 工资条 导入表格数据
 * @param file
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addPayslipData(file) {
    return   request({
        url:'/salary/excelImport',method:'post',data:file,
        headers:{
            //文件上传用该formdata类型请求头
            'Content-Type': 'multipart/form-data',
        }
    })
}

/**
 * 财务管理 工资条 导出表格数据
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPayslipTable(data) {
    return   request({
        url:'/salary/excelOutput',method:'post',data,
        headers:{
            // application/msword 表示要处理为word格式
            // application/vnd.ms-excel 表示要处理为excel格式
            'Content-Type': 'application/vnd.ms-excel',
        },
        // responseType:'arraybuffer' //在请求中加上这一行，特别重要
    })
}
/**
 * 财务管理 工资条 查询表格数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPayslipDataList(data) {
    return   request({
        url:'/salary/getSalaryMonth',method:'post',data
    })
}
/**
 * 财务管理 无卡发薪 获取列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSendSalaryList(data) {
    return   request({
        url:'/financialManagement/allDayOver',method:'post',data
    })
}
/**
 * 财务管理 无卡发薪 放款失败 列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getDayLoanFailureList(data) {
    return   request({
        url:'/financialManagement/allFailDayOver',method:'post',data
    })
}
/**
 * 财务管理 无卡发薪 修改状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterSendSalaryState(data) {
    return   request({
        url:'/financialManagement/processDayOver',method:'post',data
    })
}
/**
 * 财务管理 无卡发薪 驳回
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function rejectSendSalary(data) {
    return   request({
        url:'/financialManagement/processNotDayOver',method:'post',data
    })
}
/**
 * 财务管理 押金管理
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getDepositList(data) {
    return   request({
        url:'/financialManagement/getUserDeposit',method:'post',data
    })
}

/**
 * 财务管理 资产管理 获取账户列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAccountList(data) {
    return   request({
        url:'/assetManagement/getAccountList',method:'post',data
    })
}

/**
 * 财务管理 资产管理 添加账号
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addAccountList(data) {
    return   request({
        url:'/assetManagement/addAccount',method:'post',data
    })
}

/**
 * 财务管理 资产管理 获取当前余额
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAccountBalance(data) {
    return   request({
        url:'/assetManagement/nowMoney',method:'post',data
    })
}
/**
 * 财务管理 资产管理 获取手机验证码校验结果
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPhoneCheck(params) {
    return   request({
        url:'/assetManagement/isPhone',method:'get',params
    })
}
/**
 * 人员管理 保险管理+住宿管理--待办理--已办理
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInsureList(data) {
    return   request({
        url:'/personManagement/getContractList',method:'post',data
    })
}
/**
 * 人员管理 保险管理--待办理--已办理操作
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterInsureHandle(list) {
    return   request({
        url:'/app/staffMaage/modifyInsurance',method:'post',data:{list}
    })
}
/**
 * 人员管理 人才库
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getTalentPollList(data) {
    return   request({
        url:'/personManagement/getTalentList',method:'post',data
    })
}
/**
 * 人员管理 合同管理
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getContractList(data) {
    return   request({
        url:'/personManagement/getContract',method:'post',data
    })
}

/**
 * 人员管理 简历管理
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getResumeList(data) {
    return   request({
        url:'/personManagement/getResumeList',method:'post',data
    })
}
/**
 * 人员管理 住宿管理--宿舍列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getLodgingList(data) {
    return   request({
        url:'/personManagement/getDormitory',method:'post',data
    })
}

/**
 * 区域管理 获取区域客服信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getRegionCusSerList(data) {
    return   request({
        url:'/areaManage/oneData2',method:'post',data
    })
}

/**
 * 区域管理 获取平台客服人员列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPlatformCusSerList() {
    return   request({
        url:'/areaManage/getSystemServiceUser',method:'post'
    })
}
/**
 * 区域管理 获取企服客服列表 查询清单
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getRegionOtherList(data) {
    return   request({
        url:'/areaManage/allData',method:'post',data
    })
}
/**
 * 区域管理 获取区域经理列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getRegionManageList(data) {
    return   request({
        url:'/areaManage/allData1',method:'post',data
    })
}
/**
 * 区域管理 新增区域经理
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addRegionManage(data) {
    return   request({
        url:'/areaManage/save1',method:'post',data
    })
}

/**
 * 区域管理 获取区域经理
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getRegionManageInfo(id) {
    return   request({
        url:'/areaManage/oneData1',method:'post',data:{id}
    })
}
/**
 * 区域管理 修改区域行状态
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterRegionManageState(id,normal,type) {
    return   request({
        url:'/areaManage/changeState',method:'post',data:{id,normal,type}
    })
}

/**
 * 合同模板-查询项目列表
 * @param projectname 项目名
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getProjectList(projectname,state) {
    return   request({
        url:'/paasuser/project/getProjectList',method:'post',data:{data:{projectname,state}}
    })
}

/**
 * 合同模板-获取自定义字段
 * @param docno 模板合同编号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCustomField(docno) {
    return   request({
        url:'/et/company/contractItem/get',method:'post',data:{docno}
    })
}

/**
 * 合同模板-获取添加模板web链接
 * @param docno
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAddWebUrl(docno) {
    return   request({
        url:'/et/company/template/api/add',method:'post'
    })
}
/**
 *  项目管理 电子合同 -- 添加电子合同模板
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addETContract(data) {
    return   request({
        url:'/projectManagement/saveContract',method:'post',data
    })
}
/**
 *  项目管理 电子合同 -- 获取电子合同列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getETList(data) {
    return   request({
        url:'/et/company/template/search',method:'post',data
    })
}
/**
 *  项目管理 电子合同 -- 修改电子合同模板
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterETContract(data) {
    return   request({
        url:'/et/company/template/modify',method:'post',data
    })
}
/**
 *  项目管理 电子合同 -- 删除电子合同模板
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delETContract(id) {
    return   request({
        url:'/et/company/template/delete',method:'post',data:{id}
    })
}
/**
 *  项目管理 印章管理 -- 获取法大大文件上传接口地址
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getFaDADAUploadUrl() {
    return   request({
        url:'/et/company/upload',method:'get',
    })
}
/**
 *  项目管理 印章管理 -- 新增印章
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addSignet(data) {
    return   request({
        url:'/et/company/seal/add',method:'post',data
    })
}
/**
 * 项目管理 印章管理 -- 法大大文件下载接口
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fddDownload(data){//
    return request({
        url:'/et/company/file/get',
        method:'post',
        data,
    })
}
/**
 * 项目管理 印章管理 -- 印章删除
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delSignet(data){//
    return request({
        url:'/et/company/seal/delete',
        method:'post',
        data,
    })
}
/**
 * 项目管理 印章管理 -- 印章设置默认
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function defaultSignet(data){//
    return request({
        url:'/et/company/seal/default',
        method:'post',
        data,
    })
}
/**
 *  项目管理 印章管理 -- 查询印章
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSignetList(data) {
    return   request({
        url:'/et/company/seal/list',method:'post',data
    })
}
/**
 *  项目管理 签署记录 -- 查询列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSigningList(data) {
    return   request({
        url:'/et/company/sign/list',method:'post',data
    })
}
/**
 *  项目管理 签署记录 -- 获取法大大的合同查看权限
 * @param docNo 合同编号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSigningLook(docNo) {
    return   request({
        url:'/et/company/contract/view',method:'post',data:{docNo}
    })
}
/**
 *  项目管理 签署记录 -- 默认印章签署
 * @param docNo 合同编号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterSigningDefault(data) {
    return   request({
        url:'/et/company/sign/semiAutomatic',method:'post',data
    })
}
/**
 * 电子合同 签署记录 -- 查询批次列表
 * @param data
 * @returns {*}
 */
export function getSigningBatchList(data) {
    return   request({
        url:'/et/company/batch/search',method:'post',data
    })
}

/**
 * 电子合同 签署记录 -- 查询批次合同人员列表
 * @param data
 * @returns {*}
 */
export function getBatchPersonList(data) {
    return   request({
        url:'/et/company/contract/company/getContractHistory',method:'post',data
    })
}

/**
 * 电子合同 签署记录-详情-签署已完成
 * @param data
 * @returns {*}
 */
export function getEtFinishList(data) {
    return   request({
        url:'et/company/contract/histroy/over/get',method:'post',data
    })
}
/**
 * 电子合同 签署记录-详情-过期未签署
 * @param data
 * @returns {*}
 */
export function getEtExpireList(data) {
    return   request({
        url:'/et/company/contract/histroy/dead/get',method:'post',data
    })
}
/**
 * 众包签约 签署记录 -- 查询批次合同人员列表
 * @param data
 * @returns {*}
 */
export function getBatchPersonList2(data) {
    return   request({
        url:'/et/company/contract/company/getEasyUserContractHistory',method:'post',data
    })
}
/**
 *  众包签约 签约记录 -- 默认印章签署
 * @param docNo 合同编号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterSigningDefaultEu(data) {
    return   request({
        url:'/eu/company/sign/semiAutomatic',method:'post',data
    })
}
/**
 *  众包签约 签约记录 -- 商家 - 默认印章签署
 * @param docNo 合同编号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterSigningDefaultEu2(data) {
    return   request({
        url:'/et/company/sign/semiAutomatic2',method:'post',data
    })
}
/**
 *  项目管理 企服管理 -- 新增企服创建账号
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addEntSer(data) {
    return   request({
        url:'/areaManage/save3',method:'post',data
    })
}
/**
 * 项目管理 企服管理 -- 获取企服信息
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getEntSerInfo(id) {
    return   request({
        url:'/areaManage/oneData3',method:'post',data:{id}
    })
}
/**
 * 项目管理 企服管理 -- 修改企服信息
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterEntSerInfo(data) {
    return   request({
        url:'/areaManage/modify3',method:'post',data
    })
}

/**
 * 项目管理 企服管理 -- 修改企服状态
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterEntSerState(data) {
    return   request({
        url:'/areaManage/changeState',method:'post',data
    })
}
/**
 * 项目管理 平台企业 -- 获取平台企业列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getEntCmp(data) {
    return   request({
        url:'/projectManagement/getCompanyListPage',method:'post',data
    })
}

/**
 * 项目管理 平台企业 -- 添加平台企业
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addEntCmp(data) {
    return   request({
        url:'/projectManagement/addCompany',method:'post',data
    })
}
/**
 * 项目管理 平台企业 -- 修改、删除平台企业
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterEntCmp(data) {
    return   request({
        url:'/projectManagement/modifyCompany',method:'post',data
    })
}

/**
 * 项目管理 获取业务管理表格列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getBusinessList(data) {
    return   request({
        url:'/projectManagement/companyManageList',method:'post',data
    })
}


/**
 * 项目管理 业务管理状态修改
 * @param companyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterBusinessList(companyId) {
    return   request({
        url:'/projectManagement/modifyCompanyStatus',method:'get',params:{companyId}
    })
}

/**
 * 项目管理 获取结算规则列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getCountRuleList(data) {
    return   request({
        url:'/projectManagement/getSettlementRules',method:'post',data
    })
}

/**
 * 项目管理 添加结算规则
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addCountRule(data) {
    return   request({
        url:'/projectManagement/addSettlementRules',method:'post',data
    })
}
/**
 * 项目管理 修改结算规则
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterCountRule(data) {
    return   request({
        url:'/projectManagement/modifySettlementRules',method:'post',data
    })
}
/**
 * 运营管理 获取协议列表
 * @param companyId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getRuleList(data) {
    return   request({
        url:'/operationsManagement/getGloableDictList',method:'post',data
    })
}

/**
 * 运营管理 添加协议
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addRuleLi(data) {
    return   request({
        url:'/operationsManagement/addGloableDict',method:'post',data,
    })
}

/**
 * 运营管理 编辑协议
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function alterRuleLi(data) {
    return   request({
        url:'/operationsManagement/modifyGloableDict',method:'post',data,
    })
}

/**
 * 运营管理 删除协议
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delRuleLi(id) {
    return   request({
        url:'/operationsManagement/deleteGloableDict',method:'post',data:{id},
    })
}

/**
 * 信息配置-合同充值
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getContractNum() {
    return   request({
        url:'/paasbase/user/getMyInfo',method:'get'
    })
}

/**
 * 易用签 创建合同批次
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addEuSignBatch(data) {
    return   request({
        url:'/et/company/batch/save',method:'post',data
    })
}
/**
 * 易用签 修改合同批次
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function editEuSignBatch(data) {
    return   request({
        url:'/et/company/batch/modify',method:'post',data
    })
}

/**
 * 易用签 删除合同批次
 * @param id
 * @returns {*}
 */
export function delEuSignBatch(id) {
    return   request({
        url:'/et/company/batch/delete',method:'post',data:{id}
    })
}

/**
 * 易用签 获取合同批次人员列表
 * @param data
 * @returns {*}
 */
export function getEuBatchStaffList(data) {
    return   request({
        url:'/et/contract/batch/staff/list',method:'post',data
    })
}
/**
 * 易用签 合同批次添加人员
 * @param list
 * @returns {*}
 */
export function addEuBatchStaff(list) {
    return   request({
        url:'/et/contract/batch/staff/add',method:'post',data:{list}
    })
}
/**
 * 易用签 合同批次移除人员
 * @param list
 * @returns {*}
 */
export function delEuBatchStaff(list) {
    return   request({
        url:'/et/contract/batch/staff/remove',method:'post',data:{list}
    })
}
/**
 * 易用签 发起合同批次
 * @param batchid
 * @returns {*}
 */
export function sendEuSignBatch(batchid) {
    return   request({
        url:'/et/company/contract/send',method:'post',data:{data1:{batchid}}
    })
}
