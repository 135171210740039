import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
ElementUI.Dialog.props.modalAppendToBody.default = false
import "element-ui/lib/theme-chalk/index.css";
import "@/icon/router_icon/iconfont.css"
import "@/util/prototype"//Vue prototype自定义封装方法
Vue.use(ElementUI);
import ECharts from "echarts";
Vue.prototype.$EC = ECharts
import axios from 'axios';
import VueAxios from 'vue-axios';
import directives from '../src/util/directives'
Vue.use(directives);
Vue.use(VueAxios,axios);
import customApi from "./util/HFApi";
Vue.prototype.$iApi = customApi
import statusApi from './util/status'
Vue.prototype.$iStatus = statusApi
Vue.config.productionTip = false
Vue.component('EButton',()=>import('@/components/publicPage/EButton'))//公共效果按钮
Vue.component('EButton2',()=>import('@/components/publicPage/EButton2'))//公共效果按钮2
Vue.component('returnCmp',()=>import("@/components/publicPage/returnCmp"))//公共返回按钮
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
