import request from "../util/require";
//考勤打卡
/**
 * 考勤管理 获取表格列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getClockInMngList(data) {
    return   request({
        url:'/paasbase/attendance/getAttenceTeamList',method:'post',data
    })
}

/**
 * 考勤管理 添加考勤组
 * @param data
 * @returns {*}
 */
export function addClockInLi(data) {
    return   request({
        url:'/paasbase/attendance/addAttenceTeam',method:'post',data
    })
}

/**
 * 添加考勤电子围栏地址
 * @param data
 * @returns {*}
 */
export function addCompanyArea(data) {
    return   request({
        url:'/paasbase/attendance/addProjectArea',method:'post',data
    })
}
/**
 * 修改考勤电子围栏地址
 * @param data
 * @returns {*}
 */
export function alterCompanyArea(data) {
    return   request({
        url:'/paasbase/attendance/modifyProjectArea',method:'post',data
    })
}
/**
 * 根据项目获取公司考勤地点列表
 * @param data
 * @returns {*}
 */
export function getCompanyArea(projectid) {
    return   request({
        url:'/paasbase/attendance/ProjectArea/getList',method:'post',data:{projectid}
    })
}
/**
 * 考勤管理 修改考勤组
 * @param data
 * @returns {*}
 */
export function alterClockInLi(data) {
    return   request({
        url:'/paasbase/attendance/modifyAttenceTeam',method:'post',data
    })
}

/**
 * 考勤管理 根据考勤组获取考勤地点
 * @param teamId
 * @returns {*}
 */
export function getClockInArea(teamId) {
    return   request({
        url:'/paasbase/attendance/getAttenArea',method:'get',params:{teamId}
    })
}

/**
 * 考勤管理 考勤组停用/启用
 * @param id,status
 * @returns {*}
 */
export function alterClockInState(id,status) {
    return   request({
        url:'/paasbase/attendance/modifyStatus',method:'post',data:{id,status}
    })
}
/**
 * 考勤管理 获取考勤组人员列表
 * @param data
 * @returns {*}
 */
export function getClockInPersonnel(data) {
    return   request({
        url:'/paasbase/attendance/getAttendanceUser',method:'post',data
    })
}
/**
 * 考勤管理 添加考勤组人员
 * @param data
 * @returns {*}
 */
export function addClockInPersonnel(data) {
    return   request({
        url:'/paasbase/attendance/addAttendanceUser',method:'post',data
    })
}
/**
 * 考勤管理 移除考勤组人员
 * @param data
 * @returns {*}
 */
export function delClockInPersonnel(data) {
    return   request({
        url:'/paasbase/attendance/delAttendanceUser',method:'post',data
    })
}

/**
 * 考勤记录 获取考勤记录列表
 * @param data
 * @returns {*}
 */
export function getClockInRecordList(data) {
    return   request({
        url:'/paasbase/attendance/getAttenceHistoryList',method:'post',data
    })
}
/**
 * 考勤记录 删除考勤记录
 * @param year,id
 * @returns {*}
 */
export function delClockInRecordLi(id,year) {
    return   request({
        url:'/paasbase/attendance/delAttenceHistory',method:'post',data:{id,year}
    })
}

