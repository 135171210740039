import request from "../util/require";
//组织架构
/**
 * 获取所有功能树
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getFunctionThree() {
    return   request({
        url:'/paasuser/manager/getMenuTree',method:'get'
    })
}
/**
 * 获取用户权限树
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserThree(userId) {
    return   request({
        url:'/paasuser/manager/getAuthority',method:'get',params:{userId}
    })
}
/**
 * 组织架构 查询管理员列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getAdminList(data) {
    return   request({
        url:'/paasuser/manager/getManagerPageList',method:'post',data
    })
}
//转账记录 获取管理员列表 无分页
export function getAllAdminList(data) {
    return   request({
        url:'/paasuser/manager/getManagerListAdmin',method:'post',data
    })
}
/**
 * 组织架构 添加/修改管理员
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function addAdminLi(data) {
    return   request({
        url:'/paasuser/manager/saveManager',method:'post',data
    })
}
/**
 * 组织架构 删除管理员
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function delAdminLi(id) {
    return   request({
        url:'/paasuser/manager/removeManager',method:'post',data:{id}
    })
}
